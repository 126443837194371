/* 
import EditCategory from "cruds/category-management/edit-category";
import NewCategory from "cruds/category-management/new-category"; */
import EditCompany from "cruds/company-management/edit-company";
import NewCompany from "cruds/company-management/new-company";
import EditProject from "cruds/project-management/edit-project";
import NewProject from "cruds/project-management/new-project";
import EditProjectSiteMap from "cruds/project-management/edit-project-site-map";

import EditInspectionTemplate from "cruds/inspectiontemplate-management/edit-inspectiontemplate";
import NewInspectionTemplate from "cruds/inspectiontemplate-management/new-inspectiontemplate";
/* import EditItem from "cruds/item-management/edit-item";
import NewItem from "cruds/item-management/new-item"; */
import EditRole from "cruds/role-managament/edit-role";
import NewRole from "cruds/role-managament/new-role";
/* import EditTag from "cruds/tag-management/edit-tag";
import NewTag from "cruds/tag-management/new-tag"; */
import EditUser from "cruds/user-management/edit-user";
import NewUser from "cruds/user-management/new-user";
import EditInspection from "cruds/inspection-management/edit-inspection";
import NewInspection from "cruds/inspection-management/new-inspection";
import EditActionItem from "cruds/actionitem-management/edit-actionitem";
import NewActionItem from "cruds/actionitem-management/new-actionitem";


/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts

const crudRoutes = [
 /*  {
    key: "new-category",
    route: "/category-management/new-category",
    component: <NewCategory />,
    type: "categories",
  },
  {
    key: "edit-category",
    route: "/category-management/edit-category/:id",
    component: <EditCategory />,
    type: "categories",
  }, */
  {
    key: "new-company",
    route: "/company-management/new-company",
    component: <NewCompany />,
    type: "companies",
  },
  {
    key: "edit-company",
    route: "/company-management/edit-company/:id",
    component: <EditCompany />,
    type: "companies",
  },
  {
    key: "new-project",
    route: "/project-management/new-project",
    component: <NewProject />,
    type: "projects",
  },
  {
    key: "edit-project",
    route: "/project-management/edit-project/:id",
    component: <EditProject />,
    type: "projects",
  },
  {
    key: "edit-project-site-map",
    route: "/project-management/edit-project-site-map/:id",
    component: <EditProjectSiteMap />,
    type: "projects",
  },
  {
    key: "new-inspection",
    route: "/inspection-management/new-inspection",
    component: <NewInspection />,
    type: "inspections",
  },
  {
    key: "edit-inspection",
    route: "/inspection-management/edit-inspection/:id",
    component: <EditInspection />,
    type: "inspections",
  },
  {
    key: "new-actionitem",
    route: "actionitem-management/new-actionitem",
    component: <NewActionItem />,
    type: "inspections",
  },
  {
    key: "edit-actionitem",
    route: "/actionitem-management/edit-actionitem/:id",
    component: <EditActionItem />,
    type: "inspections",
  },
  {
    key: "new-inspectiontemplate",
    route: "/inspectiontemplate-management/new-inspectiontemplate",
    component: <NewInspectionTemplate />,
    type: "inspectiontemplates",
  },
  {
    key: "edit-inspectiontemplate",
    route: "/inspectiontemplate-management/edit-inspectiontemplate/:id",
    component: <EditInspectionTemplate />,
    type: "inspectiontemplates",
  },

  /* {
    key: "new-tag",
    route: "/tag-management/new-tag",
    component: <NewTag />,
    type: "tags",
  },
  {
    key: "edit-tag",
    route: "/tag-management/edit-tag/:id",
    component: <EditTag />,
    type: "tags",
  }, */
  {
    key: "new-role",
    route: "/role-management/new-role",
    component: <NewRole />,
    type: "roles",
  },
  {
    key: "edit-role",
    route: "/role-management/edit-role/:id",
    component: <EditRole />,
    type: "roles",
  },
  {
    key: "new-user",
    route: "/user-management/new-user",
    component: <NewUser />,
    type: "users",
  },
  {
    key: "edit-user",
    route: "/user-management/edit-user/:id",
    component: <EditUser />,
    type: "users",
  },
  /* {
    key: "new-item",
    route: "/item-management/new-item",
    component: <NewItem />,
    type: "items",
  },
  {
    key: "edit-item",
    route: "/item-management/edit-item/:id",
    component: <EditItem />,
    type: "items",
  }, */
];

export default crudRoutes;
